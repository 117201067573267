import React from 'react'
import avisionLauraFishback from '../images/avisionLauraFishback.jpg'

import laura from '../images/lauraFishbackCrop.jpg'

export default class Header extends React.Component{


    render(){
        return(
            <div>
                {/* <p>Cause No: DC-21-10885</p>
                <p>Link: <a href="https://www.dallascounty.org/government/courts/civil_district/101st/" > DALLAS COUNTY, TEXAS. 101st Civil Distric Court: Clark, Tracy</a></p>
                <img width="1000px" src={avisionLauraFishback} alt="Avision Laura"/>
                <p>Link: <a href="https://www.avisonyoung.com/en-US/professionals/-/ayp/view/laura-fishback/in/dallas">Avision Laura's professional page</a>
                </p>
                <img width="500px" src={laura} alt="Laura Fishback"/> */}

                <h1>WIP</h1>
            </div>
        )
    }

}